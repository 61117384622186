<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" md="4">
          <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            dense
            outlined
            hide-details
            label="Select Upload Type"
            v-model="Seluploadtype"
            :items="Arrayuploadtype"
            @change="btntype"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card
      v-if="chkexcel == true"
      class="pa-2 mb-1"
      flat
      :loading="loadingstatus"
      loading-text="Loading... Please wait"
    >
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="3">
          
            <v-file-input
              accept=".xlsx"
              color="green-darken-2"
              placeholder="Pick Excel file"
             prepend-icon="mdi-file-excel"
            
            
              label="Pick Excel file"
              v-model="chosenFile"
              variant="outlined"
              dense
            >
        
            </v-file-input>
          </v-col>

          <v-col cols="12" md="3" v-if="this.chosenFile!=null">
            <v-btn right color="red" @click="btnread">Submit</v-btn>
          </v-col>
          <!-- <input type="file" @change="handleFileUpload" /> -->

          <v-col cols="12" md="12">
            <v-card class="pa-1 mb-1" flat v-if="desserts.length > 0">
              <v-data-table
                v-model="TDselected"
                :headers="headers"
                :items="desserts"
                :search="search"
                item-key="application_id"
                style="border-radius: 0px"
              >
                <!-- @click="BtnApprove(index)" -->

                <template v-slot:no-data>
                  <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                  No Record(s)
                </template>
              </v-data-table>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="desserts.length > 0" @click="btnsave" color="red"
                  >Save</v-btn
                >

                <export-excel
                  v-if="desserts.length > 0"
                  :data="desserts"
                  type="button"
                  name="Complete Report"
                  style="margin-left: 10px"
                >
                  <v-btn color="success"
                    ><v-icon  left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
                  >
                </export-excel>
              </v-card-actions>
            </v-card>

           

          </v-col>
        </v-row>
      </v-card-actions>
      <v-row>
        <!-- <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select> -->

        <!-- <v-col cols="12" md="3">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col> -->
      </v-row>
    </v-card>

    
    <v-dialog
      v-model="dialogaftersave"
      persistent
      width="auto"
    >


 <v-card class="pa-1 mb-1" max-width="100%" flat v-if="dessertsaftersave.length > 0">

   <v-card-actions>
<div style="display:block">
      <v-card-title>Failed List</v-card-title>
  <v-card-subtitle>* due to duplicate or incomplete data</v-card-subtitle>
  </div>

                <v-spacer></v-spacer>

                <export-excel
                  v-if="dessertsaftersave.length > 0"
                  :data="dessertsaftersave"
                  type="button"
                  name="Complete Report"
                  style="margin-left: 10px"
                >
                  <v-btn color="success"
                    ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
                  >

                 
                </export-excel>
                  <v-btn @click="btnclose"  class="ml-2" color="error"
                    >Close</v-btn
                  >
              </v-card-actions>
              <v-data-table
                v-model="TDselectedaftersave"
                :headers="headersaftersave"
                :items="dessertsaftersave"
                :search="searchaftersave"
                item-key="application_id"
                style="border-radius: 0px"
              >
                <!-- @click="BtnApprove(index)" -->

                <template v-slot:no-data>
                  <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                  No Record(s)
                </template>
              </v-data-table>
             
            </v-card>

    </v-dialog>

    <v-card
      v-if="chkphoto == true"
      class="pa-2 mb-1"
      flat
      :loading="loadingstatus"
      loading-text="Loading... Please wait"
    >
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="3">
            <v-file-input
              accept=".zip"
              placeholder="Pick Zip file"
              prepend-icon="mdi-zip-box"
              label="Pick Zip file"
              v-model="chosenFile1"
              @change="selectFileLetter"
              variant="outlined"
              dense
            >
            
    
            
            </v-file-input>
          </v-col>

          <v-col cols="12" md="3" v-if="chosenFile1!=null">
            <v-btn right color="red" @click="btnuploadzip">Upload Zip File</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
//import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      dialogaftersave:false,
      chkexcel: false,
      chkphoto: false,
      currentFile: "",
      Arrayuploadtype: ["Excel", "Photo"],
      Seluploadtype: "",
      chosenFile: null,
      chosenFile1: null,
      selectXlsx: null,
      TDselected: [],
      TDselectedaftersave: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "8",

      SelstatusForUpade: "",

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      ReportFor: "",
      applications: [],
      applications1: [
        {
          // event_id: 0,
          //  venue_id: 0,
          // vendor_id: 0,
          fName: "",
          lName: "",
          // companytype_name: "",
          // jobtype_name: "",
          // card_name: "",
          //  picHeadShot: ""
        },
      ],
      //applications1:[]
      headers: [
        {
          text: "Preferred Family Name",
          align: "center",
          value: "Preferred Family Name",
          class: "blue lighten-5",
        },

        {
          text: "Preferred Given Name",
          value: "Preferred Given Name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Accreditation group",
          value: "Accreditation group",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Accreditation function",
          value: "Accreditation function",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Badge color",
          value: "Badge color",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Photo filename",
          value: "Photo filename",
          align: "left",
          class: "blue lighten-5",
        },
      ],

      headersaftersave: [
        {
          text: "Preferred Family Name",
          align: "center",
          value: "fName",
          class: "blue lighten-5",
        },

        {
          text: "Preferred Given Name",
          value: "lName",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Accreditation group",
          value: "companytype_name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Accreditation function",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Badge color",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Photo filename",
          value: "picHeadShot",
          align: "left",
          class: "blue lighten-5",
        },
      ],

      desserts: [],
      dessertsaftersave: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    this.$store.commit("setPageTitle", "Import");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);

    // this.bindStatus();
    // this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },
    btntype: function () {
      //alert(this.Seluploadtype);
      this.chosenFile="";
      if (this.Seluploadtype == "Excel") {
        this.chkexcel = true;
        this.chkphoto = false;
        this.chosenFile=null;
      }
      if (this.Seluploadtype == "Photo") {
        this.chkexcel = false;
        this.chkphoto = true;
      }
    },
    btnclose:function(){
 this.chkphoto = true;
              this.chkexcel = false;
              this.desserts = [];
              this.Seluploadtype = "Photo";
              this.dialogaftersave=false;
    },

    selectFileLetter(file) {
      console.log("fileNameeee");
      console.log(file);
      this.currentFile = file;
    },
    btnuploadzip: function () {
      //alert("ff");
      this.overlay = true;
      let formData = new FormData();
      formData.append("zipFile", this.currentFile);
      this.$axios
        .put("Application/uploadFromZip/" + this.SelCompany, formData)
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar("Success", "File updated!");
            this.overlay = false;
          }
        })
        .catch()
        .finally();
    },
    btnsave() {
      this.overlay = true;
      const postData = this.desserts.map((d) => ({
        event_id: this.$store.state.eventInfo.EventId,
        venue_id: this.$store.state.eventInfo.VenueId,
        vendor_id: this.SelCompany,
        fName: d["Preferred Given Name"],
        lName: d["Preferred Family Name"],
        companytype_name: d["Accreditation group"],
        jobtype_name: d["Accreditation function"],
        card_name: d["Badge color"],
        picHeadShot: d["Photo filename"],
      }));
      //this.applications.push(postData);
      console.log("new postman for insert");
      console.log({ applications: postData });
      this.$axios
        .post("Application/Bulk", { applications: postData })
        .then((res) => {
          if (res.data.status == "Success") {
            console.log("After save");
            console.log(res.data.result);
            if (res.data.result.length > 0) {
            this.showSnackbar(
              "Success",
              "Data Updated remaining failed !"
            );
            }
            else
            {
              this.showSnackbar(
              "Success",
              "Data Updated you can upload zip file!"
            );
            }
            this.dessertsaftersave = res.data.result;
            // this.dialogedit = false;
            this.overlay = false;
            if (res.data.result.length > 0) {
              this.dialogaftersave=true;
              //this.chkphoto = true;
             // this.chkexcel = true;
              //this.desserts = [];
             // this.Seluploadtype = "Photo";
            } else {
              this.chkphoto = true;
              this.chkexcel = false;
              this.desserts = [];
              this.Seluploadtype = "Photo";
            }
          }
        })
        .catch()
        .finally();
      //},

      // watch: {
      //   chkselected: function () {
      //     // this.sheet=true;
      //     if (this.chkselected.length > 0) {
      //       alert("greater");
      //     }
      //   },
    },

  
    btnread() {
      const file = this.chosenFile;
      this.desserts = [];
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const data = new Uint8Array(reader.result);
        const workbook = XLSX.read(data, { type: "array" });
        workbook.SheetNames.forEach((sheet) => {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet],
            { defval: "" }
          );
          console.log("rowObject");
          console.log(rowObject);
          this.desserts = rowObject;
          //document.getElementById("jsondata").innerHTML = JSON.stringify(rowObject,undefined,4)
        });
      };
      //  reader.readAsArrayBuffer(file);
    },

    //  const sheetName = workbook.SheetNames[0]
    //  const worksheet = workbook.Sheets[sheetName]
    //  const excelData = XLSX.utils.sheet_to_row_object_array(worksheet, { header: 1 })
    //  console.log(excelData);

    // handleFileUpload(event) {
    //   const file = event.target.files[0];
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     const data = new Uint8Array(e.target.result);
    //     const workbook = XLSX.read(data, { type: "array" });
    //     workbook.SheetNames.forEach((sheet) => {
    //       let rowObject = XLSX.utils.sheet_to_row_object_array(
    //         workbook.Sheets[sheet]
    //       );
    //       console.log(rowObject);
    //       this.desserts = rowObject;
    //     });
    //   };
    //   reader.readAsArrayBuffer(file);
    // },

    chkall: function () {},

    bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get(
          "vendor/GetByEventId/" +
            EventID +
            "/" +
            VenueID +
            "/" +
            this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
          }
        });
    },

    bindCartype: async function (event_id) {
      await this.$axios.get("Cards/GetByEventId/" + event_id).then((res) => {
        this.CardTypeForUpdate = res.data.result;
      });
    },

    bindStatus: async function () {
      await this.$axios.get("Status").then((res) => {
        this.Status = res.data.result;
        this.StatusForUpdate = res.data.result;
      });
    },

    Reload: async function () {
      this.loadingstatus = true;
      await this.$axios
        .get(
          "Report/CompleteAccrdReport/" +
            this.$store.state.eventInfo.EventId +
            "/" +
            this.$store.state.eventInfo.VenueId +
            "/" +
            this.$store.state.eventInfo.venuetype +
            "/" +
            this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          this.desserts = res.data.result;
          console.log(this.desserts);

          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style  scoped>
.tdalign {
  text-align: left;
  white-space: nowrap !important;
  background-color: brown;
}
td {
  white-space: nowrap !important;
}
th {
  white-space: nowrap !important;
}
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > th,
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th,
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tfoot
  > tr
  > th {
  text-align: left;

  white-space: nowrap !important;
}
</style>>

